<template>
  <div>
    <b-card>
      <validation-observer
        ref='form'
        v-slot='{invalid}'
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-success my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>
        <b-form
          v-else
          novalidate
          @submit.prevent='onSubmit'
        >
          <b-row class='align-items-end'>

            <b-col
              cols='12'
            >
              <LocaleTabs
                :errors='formErrors'
                name='title'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`title.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules='required'
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model='form.title[k]'
                        :placeholder="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col
              cols='12'
              xl='6'
              md='6'
              v-if='fields'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='type'
                :name="$t('labels.required_fields')"
              >
                <b-form-group
                  :label="$t('labels.required_fields')"
                >
                  <v-select
                    v-model='form.required_fields'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='availableOptions(fields, form.not_required_fields)'
                    :reduce='item => item.id'
                    :placeholder="$t('labels.required_fields')"
                    :multiple='true'
                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols='12'
              xl='6'
              md='6'
              v-if='fields'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='type'
                :name="$t('labels.required_request_fields')"
              >
                <b-form-group
                  :label="$t('labels.required_request_fields')"
                >
                  <v-select
                    v-model='form.required_request_fields'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='availableOptions(fields, form.not_required_request_fields)'
                    :reduce='item => item.id'
                    :placeholder="$t('labels.required_request_fields')"
                    :multiple='true'
                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols='12'
              xl='6'
              md='6'
              v-if='fields'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='type'
                :name="$t('labels.not_required_fields')"
              >
                <b-form-group
                  :label="$t('labels.not_required_fields')"
                >
                  <v-select
                    v-model='form.not_required_fields'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='availableOptions(fields, form.required_fields)'
                    :reduce='item => item.id'
                    :placeholder="$t('labels.not_required_fields')"
                    :multiple='true'
                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols='12'
              xl='6'
              md='6'
              v-if='fields'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='type'
                :name="$t('labels.not_required_request_fields')"
              >
                <b-form-group
                  :label="$t('labels.not_required_request_fields')"
                >
                  <v-select
                    v-model='form.not_required_request_fields'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='availableOptions(fields, form.required_request_fields)'

                    :reduce='item => item.id'
                    :placeholder="$t('labels.not_required_request_fields')"
                    :multiple='true'
                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols='12'
              xl='6'
              md='6'
              v-if='fields'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='order_fields'
                :name="$t('labels.order_fields')"
              >
                <b-form-group
                  :label="$t('labels.order_fields')"
                >
                  <v-select
                    v-model='form.order_fields'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='fields'
                    :reduce='item => item.id'
                    :placeholder="$t('labels.order_fields')"
                    :multiple='true'
                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols='12'
              xl='6'
              md='6'
              v-if='fields'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='filter_fields'
                :name="$t('labels.filter_fields')"
              >
                <b-form-group
                  :label="$t('labels.filter_fields')"
                >
                  <v-select
                    v-model='form.filter_fields'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='fields'
                    :reduce='item => item.id'
                    :placeholder="$t('labels.filter_fields')"
                    :multiple='true'
                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>


            <b-col
              cols='12'
              xl='6'
              md='6'
              v-if='usages.length'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='type'
                :name="$t('usage_id')"
                rules='required'
              >
                <b-form-group
                  :label="$t('usage_id')"
                >
                  <v-select
                    v-model='form.usage_ids'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='usages'
                    :reduce='item => item.id'
                    :placeholder="$t('usage_id')"
                    :multiple='true'
                    @input='resetParents'
                  >
                    <template #no-options>{{ $t('No options') }}</template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols='12'
              xl='6'
              md='6'
            >
              <b-form-group
                :label='$t("add_parent")'
              >
                <div
                  class='border rounded-sm p-1 d-flex custom-gap-40'
                >

                  <b-form-radio
                    v-model='is_parent'
                    :name='$t("yes")'
                    :value='1'
                  >
                    {{ $t('yes') }}
                  </b-form-radio>
                  <b-form-radio
                    v-model='is_parent'
                    :name='$t("no")'
                    :value='0'
                  >
                    {{ $t('no') }}
                  </b-form-radio>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              cols='12'
              xl='6'
              md='6'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='parent_id'
                :name="$t('amenities')"
              >
                <b-form-group
                  :label="$t('amenities')"
                >
                  <v-select
                    v-model='form.amenities'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='amenities'
                    :reduce='item => item.id'
                    :placeholder="$t('amenities')"
                    :multiple='true'
                  >
                    <template #no-options>{{ $t('No options') }}</template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols='12'
              xl='6'
              md='6'
              v-if='is_parent && parents.length'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='parent_id'
                :name="$t('parent_id')"
              >
                <b-form-group
                  :label="$t('parent_id')"
                >
                  <v-select
                    v-model='form.parent_id'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='parents'
                    :reduce='item => item.id'
                    :placeholder="$t('parent_id')"
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>


            <b-col
              md='6'
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('sort')"
                vid='sort'
                :rules='{
                         numeric: true,
                       }'
              >
                <b-form-group>
                  <template #label>
                    <span>{{ $t('sort') }}</span>
                  </template>
                  <b-form-input
                    v-model='form.sort'
                    :placeholder="$t('sort')"
                    autocomplete='off'
                    type='number'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols='12'>
              <ValidationProvider
                v-slot='{ errors }'
                vid='image'
                :name="$t('labels.main_image')"
                rules='required'
              >
                <ImagePreview
                  v-model='form.image'
                  :label="$t('labels.main_image')"
                />
                <small class='text-danger'>{{ errors[0] }}</small>
              </ValidationProvider>
              <hr class='my-1'>
            </b-col>

            <b-col cols='12' md='12' class='mt-2'>
              <validation-provider v-slot='{ errors }' name='images' vid='images'>
                <div v-if='!isUploaderLoaded'>
                  <div class='d-flex justify-content-between align-items-center'>
                    <h4>{{ $t('attach_photos') }}</h4>
                    <b-form-file
                      id='images'
                      class='hidden'
                      multiple
                      accept='image/*'
                      @change="uploader($event,'images')"
                    />
                    <label for='images' class='btn btn-primary'>
                      {{ $t('attach') }}
                    </label>
                  </div>

                  <ul v-if='images_arr.length' class='list-uploader uploader-container'>
                    <li v-for='(file, index) in images_arr' :key='index' class='uploader-item'>
                      <div class='image-box'>
                        <div class='img-container'>
                          <img :src='file.url'
                               @error='(event) => handleImageError(event, index)'
                               alt='Image'>
                        </div>
                      </div>
                      <button type='button' class='btn bg-danger uploader-btn'
                              @click="handleDeleteImage(index, 'images_arr')">
                        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#ffffff'
                             class='bi bi-trash3' viewBox='0 0 16 16'>
                          <path
                            d='M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z' />
                        </svg>
                      </button>
                      <!--                      <button type='button' class='btn bg-info uploader-btn'-->
                      <!--                              @click.prevent='downloadFile(file, "image")'>-->
                      <!--                        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='black'-->
                      <!--                             class='bi bi-arrow-bar-down' viewBox='0 0 16 16'>-->
                      <!--                          <path fill-rule='evenodd'-->
                      <!--                                d='M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5M8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6' />-->
                      <!--                        </svg>-->
                      <!--                      </button>-->
                    </li>
                  </ul>
                  <h5 v-if='!images_arr.length' class='no-files'>
                    {{ $t('No files attached') }}
                  </h5>
                  <small v-if='!images_arr.length' class='text-danger'>
                    {{ errors[0] }}
                  </small>
                </div>

                <div class='text-center text-success my-2' v-else>
                  <span class='mx-2'>{{ $t('file_processing') }}</span>
                  <b-spinner class='align-middle' />
                </div>
              </validation-provider>
            </b-col>


            <!-- checkbox -->
            <b-col cols='12'>
              <b-card-text class='mb-0'>
                {{ $t('labels.status') }}
              </b-card-text>
              <b-form-checkbox
                v-model='form.status'
                checked='1'
                value='1'
                unchecked-value='0'
                name='check-button'
                switch
              />
            </b-col>

            <!-- submit and reset -->
            <b-col cols='12 text-right'>
              <LoadingButton />
              <b-button
                type='reset'
                variant='outline-secondary'
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'
import { websiteMainUrl } from '@/services/url.service'
import { BFormRadio } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: { BFormRadio },
  mixins: [formMixin],
  data() {
    return {
      usages: [],
      parents: [],
      amenities: [],
      imagePreviews: [],
      is_parent: 0,
      form: {
        sort: null,
        title: {
          en: null,
          ar: null,
        },
        parent_id: null,
        // second_title: {
        //   en: null,
        //   ar: null,
        // },
        usage_ids: [],
        images: [],

        image: null,
        status: 1,
        required_fields: [],
        not_required_fields: [],
        required_request_fields: [],
        not_required_request_fields: [],
        order_fields: [],
        filter_fields: [],
      },

      fields: [],
      isDataLoaded: false,
      isUploaderLoaded: false,
      images_arr: [],
      images_files: [],

    }
  },

  watch: {
    images_arr: {
      handler(val = null) {
        if (val) {
          this.watchFilesChanged('images', val)
        }
      },
    },

    'form.usage_ids': {
      deep: true,
      handler(val) {
        this.getParents(val)
      },
    },
    'is_parent': {
      deep: true,
      handler(val) {
        if (val == 0) {
          this.form.parent_id = null
        }
      },
    },

  },

  computed: {
    toRedirectResource() {
      return 'real-state-type'
    },
  },
  created() {
    this.getConstants()
    if (this.isEdit) {
      this.loadData()
    } else {
      this.isDataLoaded = true
    }
  },
  methods: {
    watchFilesChanged(key, newVal) {
      if (key === 'images') {
        this.form[key] = []
        for (const el of newVal) {
          this.form[key].push(el.name)
        }
        if (this.isEdit && this.images_files.length > 0) {
          this.form[key] = this.images_files
        }
      }
    },

    handleImageError(event, index = 0) {
      if (this.imagePreviews.length > 0) {
        event.target.src = this.imagePreviews[index]
      } else {
        event.target.src = require('@/assets/images/image.svg')
      }
    },

    handleDeleteImage(index, key) {

      this[key].splice(index, 1)

      if (this.isEdit && this.images_files.length > 0 && key === 'images_arr') {
        this.images_files.splice(index, 1)
      }
    },
    uploader(e, key) {
      this.isUploaderLoaded = true

      const { files } = e.target

      if (files.length > 0) {
        const formData = new FormData()
        this.imagePreviews = [] // Reset previews

        for (const file of files) {
          formData.append('files[]', file)

          // Create a preview for each file
          const reader = new FileReader()
          reader.onload = (event) => {
            this.imagePreviews.push(event.target.result)
          }
          reader.readAsDataURL(file)
        }

        formData.append('type', 'array')

        this.axios.post(`/file/uploader/action`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(response => {
            if (!this.isEdit && key === 'images') {
              for (const el of response.data) {
                this.images_arr.push(el)
              }
            }

            if (this.isEdit && key === 'images') {
              for (const el of response.data) {
                this.images_files.push(el.name)
                this.images_arr.push(el)
              }
            }

            this.isUploaderLoaded = false
          })
          .catch(error => {
            this.isUploaderLoaded = false

            this.$toast({
              component: ToastificationContent,
              position: 'top-start',
              props: {
                title: this.$t('Error'),
                icon: 'BellIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            })
          })
      }
    },

    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            title: data.title,
            sort: data.sort,
            parent_id: data.parent_id,
            status: data.status,
            image: data.image,
            usage_ids: data.usage_ids,
            amenities: data.amenities,
            required_fields: data.required_fields || [],
            required_request_fields: data.required_request_fields || [],
            not_required_fields: data.not_required_fields || [],
            not_required_request_fields: data.not_required_request_fields || [],
            order_fields: data.order_fields || [],
            filter_fields: data.filter_fields || [],
          }

          this.images_arr = data.images_values ? data.images_values : []

          if (this.images_arr.length > 0) {
            this.images_arr.forEach(el => {
              this.images_files.push(el.url)
            })
          }

          delete this.form.images_values

          if (data.parent_id) {
            this.is_parent = 1
          }
          this.isDataLoaded = true
        })
    },
    getConstants() {

      this.axios.get(`/model-options/usage`)
        .then(res => {
          this.usages = res.data.data
        })

      this.axios.get(`/model-options/types`)
        .then(res => {
          this.parents = res.data.data
        })
      this.axios.get(`/model-options/fields`)
        .then(res => {
          this.fields = res.data.data
        })
      this.axios.get(`/model-options/amenities`)
        .then(res => {
          this.amenities = res.data.data
        })
    },

    resetParents() {
      this.form.parent_id = null
    },
    availableOptions(allOptions, selectedValues) {
      // Filter options based on the selected values and fetched values
      const fetchedValues = this.fields?.map(item => item.id)
      const optionsToShow = allOptions?.filter(option => !selectedValues.includes(option.id) && fetchedValues.includes(option.id))
      return optionsToShow
    },
    getParents(val) {
      this.parents = []
      var usages = val.join(',')
      this.axios.get(`/model-options/types?usage_id=${usages}&&is_for_admin=true`)
        .then(res => {
          this.parents = res.data.data
        })
    },
  },

}
</script>

<style>
.uploader-container {
  display: flex;
  flex-wrap: wrap;
}

.uploader-item {
  width: 19rem;
  height: 9rem;
  margin: 26px;
}
</style>
